<div class="row">
  <div class="col-md-12 nopad">
      <div class="card content ">

          <div *ngIf="appservice.openModal!=true" class=" Head ">



              <div class="card-header  d-flex justify-content-between align-items-center">
                  <h3>Item</h3>
                  <div class="dropdown">

                    <a (click)="appservice.back()" class="x">
                          <b>X</b>
                      </a>

                  </div>
              </div>

          </div>

          <div class="content_body">



              <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()"
                  novalidate>

                  <div class="row">
                      <div class="col-sm-6">

                          <div class="form-table table-responsive">
                              <table class="table table-bordered">
                                  <tbody>
                                      <tr class="hidden">
                                          <td>ID <span class="text-danger">*</span></td>
                                          <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID"
                                                  #ID="ngModel" required></td>
                                      </tr>



                                      <tr *ngFor="let data of  appservice.get_pos_field('Item_Master','1TL')">
                                          <td>{{data.Name}}  <span *ngIf="data.Validate=='True'" class="text-danger">*</span></td>

                                          <td>


                                            <select  *ngIf="(data.Type=='Select' && data.Field=='Item_Group')" class="select2-option form-control"  aria-hidden="true"
                                               required="data.Validate=='True'" [(ngModel)]="add[data.Field]"
                                                  name="{{data.Field}}" #{{data.Field}}="ngModel">
                                                 <option *ngFor="let s of appservice.Item_Group" value={{s.ID}}>
                                                     {{s.Item_Name}}
                                                 </option>
                                               </select>


                                              <select  *ngIf="(data.Type=='Select' && data.Field!='Item_Group')" class="select2-option form-control"  aria-hidden="true"
                                               required="data.Validate=='True'" [(ngModel)]="add[data.Field]"
                                                  name="{{data.Field}}" #{{data.Field}}="ngModel">
                                                 <option *ngFor="let row of appservice.get_ref(data.Options)" value={{row[data.Store_Value]}}>
                                                     {{row.label}}
                                                 </option>
                                               </select>

                                              
                                              <input *ngIf="data.Type!='Select'" class="form-control cls_0"   [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" type="{{data.Type}}" [(ngModel)]="add[data.Field]"
                                                  name="{{data.Field}}" #{{data.Field}}="ngModel"   >
                                              
                                                  <div *ngIf='addForm.submitted && (data.Validate=="True") && (add[data.Field]=="")' class="invalid-feedback d-block">
                                                      <div class="error"> {{data.Name}} Should not entry</div>
                                                  </div>  
                                              </td>
                                      </tr>


                                      <tr *ngIf="appservice.img_visible" >
                                        <td>Image</td>
                                        <td>
                                          <p-fileUpload name="myfile[]" url="{{server}}" (onUpload)="onUpload($event)"
                                            multiple="multiple" maxFileSize="1000000">
                                            <ng-template pTemplate="content">
                                              <ul *ngIf="uploadedFiles.length">
                                                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                                              </ul>
                                            </ng-template>
                                          </p-fileUpload>

                                          <img class="icon-img" style="width: 150px;"
                                          src="{{appservice.Server_URL}}/image/C{{appservice.Company}}/{{add.Item_Code}}.png?{{date}}"
                                          alt="First slide">
                
                                        </td>
                                      </tr>
                                  

                                  </tbody>
                              </table>


                              <div class="invoice-btn-box text-right " style="padding-bottom:20px">

                                  <button class="btn btn-info rounded-pil" [disabled]="btndisable" type="submit">


                                      <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm"
                                          role="status" aria-hidden="true"></span>
                                      <i *ngIf="btndisable==false" class='bx bx-paper-plane'></i>
                                      Save </button> &nbsp;&nbsp;
                                  <button   *ngIf="appservice.openModal!=true"  class="btn btn-secondary rounded-pil " type="button"
                                      (click)="appservice.back()">Cancel</button> &nbsp;&nbsp;
                              </div>

                          </div>
                      </div>



                      <div *ngIf="appservice.UOM_Price_List==true" class="col-sm-6">


                        
                      <label>Price Details</label>



                        <table style="width: 100%;">
                            <tr>
                                <td> 1 KG Price</td>
                                <td><input type="number"  class="form-control form-control-sm "
                                    
                                    [(ngModel)]="kg_Price"
                                                  name="kg_Price1" #kg_Price1="ngModel"
                                                  ></td>

                                <td>
                                    <input type="button"  class="btn btn-secondary  btn-sm"  style="width: 100%;"  (click)="Change_Price()" value="Update">
                                </td>

                                <td>

                                    <button class="btn btn-success btn-sm" style="width: 100%;" (click)="Update_Price()" [disabled]="appservice.loading" type="button">


                                        <span *ngIf="appservice.loading==true" class="spinner-border spinner-border-sm"
                                            role="status" aria-hidden="true"></span>
                                        <i *ngIf="appservice.loading==false" class='bx bx-paper-plane'></i>
                                        Save  </button>


                                    
                                    
                                </td>

                            </tr>
                        </table>
                       <table #dt2 class="table table-bordered table-sm">
                           <tr>
                           
                               <th>UOM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                               <th style="text-align: center;">Size</th>
                               <th style="text-align: center;">Rate</th>
                               <th style="text-align: center;">MRP</th>
                               
                           </tr>

                           <tr *ngFor="let data of Items; let i = index">
                            
                            <td>{{data.UOM}}</td>
                            <td> <input type="number"  class="form-control form-control-sm tb_text_r"
                                [(ngModel)]="data.Size" name="{{data.Size}}{{i}}" #{{data.Size}}{{i}}="ngModel"></td>
                            <td style="text-align: right;">
                                <input type="number"  class="form-control form-control-sm tb_text_r"
                                [(ngModel)]="data.Rate"
                                                  name="{{data.Rate}}{{i}}" #{{data.Rate}}{{i}}="ngModel">
                                </td>
                            <td style="text-align: right;" >
                                 <input type="number"  class="form-control form-control-sm tb_text_r"
                                [(ngModel)]="data.MRP"
                                                  name="{{data.MRP}}{{i}}" #{{data.MRP}}{{i}}="ngModel">
                            </td>
                           
                        </tr>
                       </table>

                       
                       <button class="btn btn-success btn-sm" (click)="add_New_UOM()" [disabled]="appservice.loading" type="button">


                        <span *ngIf="appservice.loading==true" class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                        <i *ngIf="appservice.loading==false" class='bx bx-paper-plane'></i>
                        Add New Size </button> &nbsp;&nbsp;




                                    
                      </div>

                  </div>
              </form>
          </div>
      </div>
  </div>