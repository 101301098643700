import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-add-product1',
  templateUrl: './add-product1.component.html',
  styleUrls: ['./add-product1.component.scss']
})
export class AddProduct1Component implements OnInit {


  rate_disp:boolean=false;
  Category_="";
  constructor(private _location: Location, public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute)  
  { 
    this.appservice.get_stcok_Category();
    if(appservice.U_Rights!=appservice.PRate_disp)
    {
      this.rate_disp=true;
    }
    else
    {
      this.rate_disp=false;
    }

   

    try
    {
      this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Order_Rate))), 0)).toFixed(2);

    }catch{}
   
   }

   Search_text_="";


   Search_Item_byName(data) {

    
    
    if (data == "All") {
      this.appservice.Stcok_Row = this.appservice.P_Stcok_Row ;

    }
    else {
      this.appservice.Stcok_Row = this.appservice.P_Stcok_Row.filter(e => e.Item_Name.toLowerCase().includes(data.toLowerCase()));
    }
    this.appservice.load_Temp_Order_Row()

    

  }



  isError() :boolean {
    return this.iserror;
  }


   public iserror: boolean=false;
   get_filter_data()
   {
   
    
    this.appservice.get_Item_Master1(this.appservice.Selected_Customer.ID);

   }

  txt_search="";
  ngOnInit(): void {
  }

 
  public rows = [];
  public add: any = {};
  
   Total="0.00";

  add_row(data)
  {

    this.iserror=false;
    var index = this.appservice.Temp_Order_Row.findIndex(function (item, i) {
      return (item.Uni_Code == data.Uni_Code && item.Item_ID == data.Item_ID)
    });
    
    
      if (index > -1) {
        this.appservice.Temp_Order_Row.splice(index, 1);
      }
      this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Order_Rate))), 0)).toFixed(2);


    if(data.Order_Rate=="")
    {
      data.error="";
      return;
    }
    
    if(data.Order_Qty=="")
    {
      data.error="";
      return;
    }

       
    if(Number(data.Order_Qty)==0 )
    {
      data.error="";
      return;
    }

       
    if(Number(data.Order_Rate)==0 )
    {
      this.iserror=true;
      data.error="";
      return;
    }

   /* if((Number(data.Order_Rate)>Number(data.MRP))  && (Number(data.MRP)>0) )
    {
      this.iserror=true;
      data.error="Rate should not be More than MRP";
      return;
    }*/

    if(Number(data.Order_Rate)<Number(data.Purchse_Price) )
    {
      this.iserror=true;
      data.error="Rate should not be less than Purchse Price";
      return;
    }

    
    if((Number(data.Order_Qty)+Number(data.Free))>Number(data.Stock) && (Number(data.Stock)>0) )
    {this.iserror=true;
      data.error="More than Stock";
      return;
    }

        
    if(Number(data.Order_Qty)<0 )
    {
      this.iserror=true;
      data.error="Not Valid Qty ";
      return;
    }

    this.iserror=false;

    data.error="";

    
      if(Number(data.Order_Qty)>0  && Number(data.Order_Rate)>0)
      {
          this.appservice.Temp_Order_Row.push(data);

      }

      this.Total = (this.appservice.Temp_Order_Row.reduce((sum, current) => sum + ((parseFloat(current.Order_Qty))*(parseFloat(current.Order_Rate))), 0)).toFixed(2);


  }

  public addValidation: boolean = false;
   headers;
   data;
   isadd = "0";
   isload:boolean=false;

   

}
