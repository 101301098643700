<div class="row">
  <div class="col-md-12 nopad">
    <div class="card content ">

      <div class=" Head ">
        <div class="card-header  d-flex justify-content-between align-items-center">
          <h3>Document Details</h3>

          <div class="dropdown">



            <a class="dropdown-toggle   addbtn" (click)=" addReset()" data-toggle="tooltip" data-placement="bottom"
              title="Add New" >
              <i class='bx bx-list-plus'></i>
            </a>

            <a style="padding-left: 10px;padding-right: 10px;" class="dropdown-toggle "
              (click)="appservice.get_Douments()" data-toggle="tooltip" data-placement="bottom" title="Refresh">

              <i class='bx bx-rotate-left'></i>
            </a>


          </div>
        </div>

      </div>

      <div class="content_body">

        <div style="margin-top: 10px;margin-bottom: 10px;">
          <span >Select Type</span>

          <select class="form-control" style="max-width: 250px;"
          [(ngModel)]="appservice.Document_Type" required
                            name="Document_Type" #Document_Type="ngModel" (change)="display()" >
           

            
            <option>Front_Banner</option>
            <option>Offers1</option>
            <option>Offers2</option>
        </select>


        </div>
        <div style="width: 100%;">


          <p-table #dt class="table table-striped custom-table" [value]="Rows" [columns]="cols" [paginator]="true"
            [rows]="10" [resizableColumns]="true" sortMode="multiple" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of cols" [pSortableColumn]="col.field" width="{{col.width}}"
                  style="white-space: pre">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order">
                  </p-sortIcon>
                </th>
                <th width=90>Image</th>
                <th width=90>Action</th>
              </tr>

              <tr *ngIf="filter">
                <td *ngFor="let col of cols" [ngSwitch]="col.field">
                  <input style="height: 30px;width:100%;" class="form-control" pInputText type="text"
                    (input)="dt.filter($event.target.value, col.field, 'contains')">
                </td>
                <td width=90></td>
              </tr>

            </ng-template>
            <ng-template pTemplate="body" let-rowData let-car>
              <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of cols">
                  {{car[col.field]}}
                </td>

                <td> <img height="50" src="{{appservice.Server_URL}}/Image/C{{appservice.Company}}/Document/D_{{rowData.ID}}.png"> </td>
                <td class="text-right" style="white-space: pre">
                  <a href="javascript:" style="font-size: 18px;padding: 5px" title="Edit" (click)="onEdit(rowData)"><i
                      class="bx bxs-pencil"></i>
                  </a>
                  <a title="Delete" style="font-size: 18px;padding: 5px;color: firebrick"
                    (click)="onDelete(rowData.ID)"><i class="bx bxs-trash"></i>
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <br>
      </div>
    </div>
  </div>
</div>