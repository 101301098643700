import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnalyticsComponent } from './components/pages/dashboard/analytics/analytics.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard/dashboard.component';
import { EcommerceComponent } from './components/pages/dashboard/ecommerce/ecommerce.component';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { RegisterComponent } from './components/pages/authentication/register/register.component';
import { ForgotPasswordComponent } from './components/pages/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/authentication/reset-password/reset-password.component';
import { ReferenceDetailsComponent } from './master/reference-details/reference-details.component';
import { ReferencMasterComponent } from './master/referenc-master/referenc-master.component';
import { ItemMasterComponent } from './master/item-master/item-master.component';
import { AddBankComponent } from './master/add-bank/add-bank.component';
import { BankDetailsComponent } from './master/bank-details/bank-details.component';
import { AddItemComponent } from './master/add-item/add-item.component';
import { CustomersComponent } from './master/customers/customers.component';
import { CustomerDetailsComponent } from './master/customer-details/customer-details.component';
import { UserMasterComponent } from './master/user-master/user-master.component';
import { AddUserComponent } from './master/add-user/add-user.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderEntryComponent } from './order-entry/order-entry.component';
import { BannerUploadComponent } from './banner-upload/banner-upload.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { CategoryImageComponent } from './category-image/category-image.component';
import { DocumentUploadDetailsComponent } from './document-upload-details/document-upload-details.component';
import { RoleRolesPermissionsComponent } from './role-roles-permissions/role-roles-permissions.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { AddProductComponent } from './add-product/add-product.component';
import { SalesEntryComponent } from './sales-entry/sales-entry.component';
import { PaymodeSelectComponent } from './paymode-select/paymode-select.component';
import { SalesDetailsComponent } from './transaction/sales-details/sales-details.component';
import { ViewOrderDetailsComponent } from './view-order-details/view-order-details.component';
import { UpdateCategoryComponent } from './update-category/update-category.component';
import { UpdateCompanyDetailsComponent } from './update-company-details/update-company-details.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { AddProduct1Component } from './add-product1/add-product1.component';
import { GenerateBillsComponent } from './generate-bills/generate-bills.component';
import { NotificatonComponent } from './notificaton/notificaton.component';
import { AddItem1Component } from './master/add-item1/add-item1.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { SettingDashboardComponent } from './setting-dashboard/setting-dashboard.component';
import { CutomerSearchComponent } from './cutomer-search/cutomer-search.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { B2CReportComponent } from './report/GST-Report/b2-c-report/b2-c-report.component';
import { BillwiseProfitComponent } from './report/Profit-Report/billwise-profit/billwise-profit.component';
import { ItemwiseProfitComponent } from './report/Profit-Report/itemwise-profit/itemwise-profit.component';
import { CustomerwiseProfitComponent } from './report/Profit-Report/customerwise-profit/customerwise-profit.component';
import { OverAllProfitComponent } from './report/Profit-Report/over-all-profit/over-all-profit.component';
import { SalesDashboardTwoComponent } from './sales-dashboard-two/sales-dashboard-two.component';
import { ViewOrderDetails1Component } from './view-order-details1/view-order-details1.component';
import { InvalidLicenceComponent } from './invalid-licence/invalid-licence.component';
import { AddItemsComponent } from './add-items/add-items.component';
import { UserwiseSalesComponent } from './report/userwise-sales/userwise-sales.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { SalesVsRMComponent } from './report/sales-vs-rm/sales-vs-rm.component';
import { ItemwiseSalesVsRMComponent } from './report/itemwise-sales-vs-rm/itemwise-sales-vs-rm.component';
import { SaleswiseProfitComponent } from './saleswise-profit/saleswise-profit.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { ReferenceGroupComponent } from './reference-group/reference-group.component';
import { AddReferenceGroupComponent } from './add-reference-group/add-reference-group.component';



const routes: Routes = [
  { path: 'reference-group', component: ReferenceGroupComponent },
  { path: 'add-reference-group', component: AddReferenceGroupComponent },
  { path: 'invalid-licence', component: InvalidLicenceComponent },

  { path: 'add-role', component: AddRoleComponent },
  { path: 'user-wise', component: UserwiseSalesComponent },
  { path: 'analysis', component: AnalysisComponent },

  { path: 'add-items1', component: AddItem1Component },
  { path: 'cutomer-search', component: CutomerSearchComponent },

  { path: 'reference', component: ReferenceDetailsComponent },
  { path: 'add-reference', component: ReferencMasterComponent },

  { path: 'add-items', component: AddItemComponent },
  { path: 'item-details', component: ItemMasterComponent },

  { path: 'add-bank', component: AddBankComponent },
  { path: 'bank-details', component: BankDetailsComponent },

  { path: 'add-customer', component: CustomersComponent },
  { path: 'customer-details', component: CustomerDetailsComponent },
  { path: 'setting-dashboard', component: SettingDashboardComponent },
  { path: 'change-password', component: ChangePasswordComponent },



  { path: 'order-details', component: OrderDetailsComponent },
  { path: 'view-order-details1', component: ViewOrderDetails1Component },

  { path: 'view-order-details', component: ViewOrderDetailsComponent },
  { path: 'order-entry', component: OrderEntryComponent },

  { path: 'banner-upload', component: BannerUploadComponent },

  { path: 'document-upload', component: DocumentUploadComponent },
  { path: 'document-upload-details', component: DocumentUploadDetailsComponent },

  { path: 'category-image', component: CategoryImageComponent },

  { path: 'update-category', component: UpdateCategoryComponent },
  { path: 'update-company', component: UpdateCompanyDetailsComponent },
  { path: 'user-details', component: UserMasterComponent },
  { path: 'user-profile', component: UserProfileComponent },

  { path: 'add-item', component: AddItemsComponent },
  
  
  { path: 'add-user', component: AddUserComponent },
  { path: 'role-permissions', component: RoleRolesPermissionsComponent },
  { path: 'search_customer', component: SearchCustomerComponent },
  { path: 'add-product', component: AddProductComponent },
  { path: 'add-product1', component: AddProduct1Component },
  { path: 'sales-entry', component: SalesEntryComponent },
  { path: 'sales-details', component: SalesDetailsComponent },
  { path: 'paymode-select', component: PaymodeSelectComponent },
  { path: 'generate-bills', component: GenerateBillsComponent },
  { path: 'sales-dashboard-two', component: SalesDashboardTwoComponent },

  { path: 'notification', component: NotificatonComponent },
 
  { path: 'role-master', component: RoleMasterComponent },
  { path: 'sales-dashboard', component: SalesDashboardComponent },
//gst-report
{ path: 'B2C-Report', component: B2CReportComponent },

  //profit Billwise Profit
  { path: 'Sales_Vs_RM', component: SalesVsRMComponent },
  { path: 'itemwiseSales_Vs_RM', component: ItemwiseSalesVsRMComponent },
{ path: 'Billwise-Profit', component: BillwiseProfitComponent },
{ path: 'Itemwise-Profit', component: ItemwiseProfitComponent },
{ path: 'Customerwise-Profit', component: CustomerwiseProfitComponent },
{ path: 'Over-all', component: OverAllProfitComponent },
{ path: 'Sales-profit', component: SaleswiseProfitComponent },

  
  { path: '', component: LoginComponent },
  
  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      { path: 'analytics', data: { breadcrumb: 'Analytics' }, component: AnalyticsComponent },
      { path: 'e-commerce', data: { breadcrumb: 'eCommerce' }, component: EcommerceComponent },
    ]
  },




  {
    path: 'authentication', component: AuthenticationComponent,
    children: [
      { path: 'login', pathMatch: 'full', data: { breadcrumb: 'Login' }, component: LoginComponent },
      { path: 'register', data: { breadcrumb: 'Register' }, component: RegisterComponent },
      { path: 'forgot-password', data: { breadcrumb: 'Forgot Password' }, component: ForgotPasswordComponent },
      { path: 'reset-password', data: { breadcrumb: 'Reset Password' }, component: ResetPasswordComponent },
    ]
  },


  { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule) },



  { path: 'report', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },


  { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule) },


  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },


  { path: 'Purchase', loadChildren: () => import('./purchase/purchase.module').then(m => m.PurchaseModule) },


  { path: 'master', loadChildren: () => import('./master/master.module').then(m => m.MasterModule) },


  


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
