<div class="row">
    <div class="col-md-12 nopad">
  
      
        <div class="col-md-12 nopad mobile_hide">
  
  
          <div class="w_79" style="position: fixed;z-index: 99; ">
            <div class="tabs-header border-bottom">
              <ul>
  
                <li > <a (click)="appservice.load_page('/Sales_Vs_RM')">Sales Vs RM</a></li>
                <li class="active"><a (click)="appservice.load_page('/itemwiseSales_Vs_RM')"> Itemwise </a></li>
              
  
              </ul>
            </div>
  
  
            <div class="right-corner">
  
              <div class="dropdown"style="display: inline-flex;">
  
              
              <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" data-placement="bottom"
                title="search" >
               
                  <i style="    font-size: 20px;" class='bx bx-search'></i> 
                </a>
  
  
                <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                  aria-expanded="false">
                  <i class='bx bx-filter'></i>
                </button>
  
                <div class="dropdown-menu" style="width: 325px;">
                  <div style="background-color: teal; color: white; width: 100%; ">
                    &nbsp;&nbsp;Search</div>
                  <table>
  
  
  
                    <tr >
                      <td style="width: 80px;">Date </td>
                      <td>
                        <input type="date" class="form-control form-control-sm" [(ngModel)]="appservice.S_From"
                          name="From_" #From_="ngModel">
  
                      </td>
                    </tr>
  
  
  
                 
                    <tr>
                      <td> </td>
                      <td><button type="button" (click)="appservice.get_Sales_vs_RM()"
                          class="btn btn-info btn-sm">Search</button></td>
                    </tr>
  
  
                    
                  </table>
                  <div  class="search_Footer border-top" style="color: green;"> 
                    <div  class="buttons">
                      <span><li class="bx bx-download"></li></span>
                     <span> Export Excel</span>
                     
                    </div>
    
                   
                    <div  class="buttons " style="color: red;">
                      <span><li class="bx bx-download"></li></span>
                     <span> Export PDF</span>
                     
                    </div>
    
    
                </div>
  
                </div>
  
              </div>
  
            </div>
  
          </div>
        </div>
      
  
    
        <div class="col-md-12 nopad">
  
  
          <div class="spin_block" *ngIf="appservice.isorder_Load">
  
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
  
          </div>
          <div class="search-bar_Ext mhide  show_bar " style="right: 80px;">
          
            <li  class="bx bx-left-arrow-alt mshow  search_back"></li>
  
                <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                class="search-form-control text"
                placeholder="Search...">
    
          </div>
  
  
          <div class="m_2 res_table">
            <p-table #dt  class="table table-striped custom-table" [value]="appservice.I_Sales_vs_RM_Rows" 
            [globalFilterFields]="['Item_Name']"
  
            selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
  
  
                  <th>#</th>
                  <th >Item Name </th>
                  <th >Qty</th>
                
                 
  
  
                </tr>
  
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                 <td>{{rowIndex+1}}</td>
                 
                  <td  >{{rowData.Item_Name}}</td>
                 
                  <td style="text-align: right;">{{rowData.Qty}}</td>

                    
  
                </tr>
              </ng-template>
            </p-table>
  
  
  
  
          </div>
        </div>
      
  
     
     
    </div>
  </div>