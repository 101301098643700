<div class="row">
    <div class="col-md-12 nopad">
        <div class="card content ">

            <div class=" Head ">
                <div class="card-header  d-flex justify-content-between align-items-center">
                    <h3>Order Details</h3>

                    
                  

                    <a (click)="addReset()"  class="x">
                        <b>X</b>
                    </a>


                    
                </div>

            </div>

            <div class="content_body">


                <div *ngIf="(appservice.Selected_Customer | json) == '{}'" >
                    <div class="d-table" style="width:100%;">
                        <div class="d-table-cell">
                            <div class="maintenance-content" style="box-shadow:none">
                              
                                <a style="height: 100px; width: 100px;" class="logo">
                                    <img src="assets/img/Orders.png" alt="image">
                                </a>
                               
                                <p>Order entry allows you to record information about your customers and the items they have ordered</p>
                
                                <a  routerLink="/search_customer" class="default-btn">Select Customer</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="invoice-middle mb-30" *ngIf="(appservice.Selected_Customer | json) != '{}'">
                    <div class="row">
                        <div class="col-6 col-md-9">
                            <div class="text" >
                               
                                <div >
                                <h4 class="mb-2">To:-</h4>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Ledger_Name}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address1}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address2}} </span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address3}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Address4}}</span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.City}} </span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.State}} </span>
                                <span class="d-block mb-1">{{appservice.Selected_Customer.Pincode}}</span>
        
        
                            </div>
                            </div>
                        </div>
            
                     
            
                        <div class="col-6 col-md-3" *ngIf="(appservice.Selected_Customer | json) != '{}'">
                            <div class="text text-right">
                                <table style="width: 98%;">
                                    <tr>
                                       
                                        
                                        <td style="text-align: right; font-weight: 700;">{{appservice.Order_No}}</td>
                                    </tr>
                                    <tr>
                                      
                                        <td style="text-align: right;font-weight: 700;">{{appservice.Today_Date}}</td>
                                    </tr>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>


                
           


                <div class="row"   *ngIf="appservice.Details_Row.length>0">

                    <div class="col-md-8 mpx" >
                        <div class="cart-view">
                            <div class="head_text">Items Details
                            
                            </div> 
                            <table>


                
                                <tr class="border-bottom" *ngFor="let data of appservice.Details_Row; let i=index">
                
                                    <td style="width: 50px;  padding-left: 5px;
                                    padding-right: 0px;">
                                    {{i+1}}
                                        <img  *ngIf="appservice.Image_disp==true" style="height: 40px; width: 40px;" src="{{appservice.Server_URL}}/image/C{{appservice.Company}}/I{{data.ITEM_ID}}.jpg" class="rounded-circle" alt="image">
                                    </td>
                                    <td style=" padding-left: 5px; padding-top: 0px;
                                    padding-right: 2px;">
                                        <div class="name">
                                            {{data.Item_Name}}    &nbsp;&nbsp; Rs.{{data.MRP}}  
                                        </div>
                                        <div class="desc">
                                            {{data.Category}}  
                                        </div>
                
                
                                    </td>
                                    <td style="text-align: right; ">
                                       
                                        <div class="mrp">
                                            Qty : <span >{{data.Qty}}  <span *ngIf="data.Free!=0"> + {{data.Free}}</span>  X ₹ {{data.Unit_Price}}</span>
                                        </div>
                
                                        <div class="price" style="padding-top: 0px;">
                                            ₹ {{(data.Qty*data.Unit_Price).toFixed(2)}}
                                        </div>
                
                                    </td>
                
                                </tr>
                            </table>
                
                        </div>
                
                    </div>
                    <div class="col-md-4 mpx" *ngIf="appservice.Details_Row.length>0">
                
                
                        <div class="cart-summary">
                
                        <div class="head_text">Order summary</div> 
                            <table>
                
                                <tr>
                
                                    <td>
                                        <div class="name">
                                            Sub Total
                                        </div>
                
                
                
                                    </td>
                                    <td style="text-align: right; ">
                                        <div class="price">
                                            ₹ {{this.appservice.header_Row.Sub_Total}}
                                        </div>
                
                
                                    </td>
                
                                </tr>
                
                
                       
                
                                <tr>
                
                                    <td style="    border-bottom: 1px dotted #dee2e6 !important;">
                                        <div class="name">
                                            GST
                                        </div>
                
                
                
                                    </td>
                                    <td style="text-align: right;   border-bottom: 1px dotted #dee2e6 !important;">
                                        <div class="price">
                                            ₹ {{this.appservice.header_Row.Tax_Amt}}
                                        </div>
                
                
                                    </td>
                
                                </tr>
                
                
                
                                <tr>
                
                                    <td>
                                        <div class="name">
                                            Order Value
                                        </div>
                
                
                
                                    </td>
                                    <td style="text-align: right; ">
                                        <div class="price">
                                            ₹ {{this.appservice.header_Row.Net_Amt}}
                                        </div>
                
                
                                    </td>
                
                                </tr>
                
                                
                
                
                                <tr>
                
                                    <td colspan="2" style="text-align: center;">
                                        
                                        <button (click)="Place_Order()" class="btn btn-info btn-block" style="border-radius: 0px;color: white;" 
                                        [disabled]="btndisable" > 
                                        <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  
                                        <i   *ngIf="btndisable==false" class='bx bx-paper-plane'></i> Finish
                                        </button>
                                        
                                        <a style="border-radius: 0px;color: white;"  (click)="appservice.back()"   class="btn btn-danger btn-block">Back</a>
                        
                        

                                        
                
                                    </td>
                                   
                
                                </tr>
                
                
                            </table>
                
                        </div>
                    </div>
                
                
                
                </div>

            </div>
        </div>
     </div>
</div>

