<div class="row">
    <div class="col-md-12 nopad">
      <div class="card content ">
  
        <div class=" Head ">
  
          <div class="card-header  d-flex justify-content-between align-items-center">
            <h3>Amount collection</h3>
  
            <div class="dropdown">
  
              <a (click)="Back()" class="x">
                <b>X</b>
              </a>
  
  
            </div>
          </div>
  
        </div>
  
        <div class="content_body">
  
  
  
          <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()" novalidate>
  
  
  
            <div class="row">
  
              <div class="col-sm-6 nopad" style="padding-left: 4px;">
  
                <div class="form-table table-responsive">
                  <table class="table table-bordered">
  
  
                    <tbody>
                      <tr class="hidden">
                        <td>ID <span class="text-danger">*</span></td>
                        <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID" #ID="ngModel" required>
                          
            <input class="form-control" type="text" [(ngModel)]="add.Narration1" name="Narration1" #Narration1="ngModel">
            <input class="form-control" type="text" [(ngModel)]="add.Narration2" name="Narration2" #Narration2="ngModel">
                        </td>
                      </tr>
  
  
                      <tr>
                        <td style="width: 100px;">Customer<span class="text-danger">*</span></td>
                        <td style="padding: 0px!important;">
  
                          <table style="width: 100%;"> 
                            <tr>
                              <td class="hidden">
  
                                <input class="form-control" (click)="load_p()"  style="background-color: white;" readonly type="number" required [(ngModel)]="add.Ledger_ID" name="Ledger_ID"
                                #Ledger_ID="ngModel">
  
                              <div *ngIf="addForm.submitted && Ledger_ID.invalid" class=" invalid-feedback d-block">
                                <div class="error">Customer  Should not entry</div>
                              </div>
                              </td>
  
                              <td>
  
                                <input class="form-control" readonly type="text"required [(ngModel)]="add.Ledger_Name" name="Ledger_Name"
                                #Ledger_Name="ngModel">
  
                              <div *ngIf="addForm.submitted && Ledger_Name.invalid" class=" invalid-feedback d-block">
                                <div class="error">Customer Should not entry</div>
                              </div>
  
                              <li  (click)="load_p()"   class="bx bx-search sicon"></li>
  
                              </td>
  
  
                             
                            </tr>
                          </table>
                         
                        </td>
                      </tr>
  
  
                      <tr>
                        <td>Bill No<span class="text-danger">*</span></td>
                        <td> 
  
  
                          <select class="select2-option form-control " required [(ngModel)]="add.Bill_No" name="Bill_No" 
                          #Bill_No="ngModel" style="width:100%"  (change)="get_OS_Bill_Amount(add.Bill_No)" aria-hidden="true">
    
                                <option *ngFor="let data of Bill_No_" value={{data.value}}>
                                    {{data.label}}
                                </option>
    
                            </select>
  
                        <div *ngIf="addForm.submitted && Bill_No.invalid" class=" invalid-feedback d-block">
                          <div class="error">Bill No Should not entry</div>
                        </div>
  
                        </td>
                      </tr>
  
  
                      <tr>
                        <td>Bill Amount<span class="text-danger">*</span></td>
                        <td>
                          <input class="form-control" readonly type="number"required [(ngModel)]="add.Bill_Amount" name="Bill_Amount"
              #Bill_Amount="ngModel">
              <div *ngIf="addForm.submitted && Bill_Amount.invalid" class=" invalid-feedback d-block">
                <div class="error">Bill Amount Should not entry</div>
            </div>
  
          </td>
                      </tr>
  
  
                      <tr>
                        <td> Amount<span class="text-danger">*</span></td>
                        <td>
                          <input class="form-control" type="number"required [(ngModel)]="add.Received_Amount" name="Received_Amount"
                          #Received_Amount="ngModel">
                          <div *ngIf="addForm.submitted && Received_Amount.invalid" class=" invalid-feedback d-block">
                            <div class="error">Receipt Amount Should not entry</div>
                        </div>
                        </td>
                      </tr>
  
                      
                      <tr >
                        <td>Pay Mode</td>
                        <td  style="text-align: center;">
    
    
                            <select class="select2-option form-control " [(ngModel)]="appservice.Pay_Mode" name="Pay_Mode"
                                #Pay_Mode="ngModel" style="width:100%" aria-hidden="true">
    
                                <option *ngFor="let data of Pay_Mode_" value={{data.value}}>
                                    {{data.label}}
                                </option>
    
                            </select>
    
    
                            <div *ngIf="addForm.submitted && Pay_Mode.invalid" class=" invalid-feedback d-block">
                              <div class="error">Pay Mode Should not entry</div>
                            </div>
    
    
    
                        </td>
    
                    </tr>
  
                      <tr  [className]="appservice.Pay_Mode==appservice.Cash_ID ? 'hidden' : ''" >
    
                        <td colspan="2" style="padding: 0px;">
                        <table style="width: 100%; padding: 0px;" >
                            <tr  style="padding: 0px;" >
                                <td style="width: 100px;">Rec. Bank</td>
                                <td>
                                    
                                    <select class="select2-option form-control " [(ngModel)]="appservice.Receiving_Bank" name="Receiving_Bank"
                                    #Receiving_Bank="ngModel" style="width:100%" aria-hidden="true">
            
                                    <option *ngFor="let data of Banks_" value={{data.value}}>
                                        {{data.label}}
                                    </option>
            
                                </select>
                            
                            </td>
                            </tr>
            
                            <tr [className]="appservice.Pay_Mode==appservice.Cheque_ID ? '' : 'hidden'">
                                <td>Cheque No </td>
                                <td> <input class="form-control" type="text" [(ngModel)]="appservice.Cheque_No"
                                    name="Cheque_No" #Cheque_No="ngModel"></td>
                            </tr>
            
                            <tr [className]="appservice.Pay_Mode==appservice.Cheque_ID ? '' : 'hidden'" >
                                <td>Cheque Date</td>
                                <td><input class="form-control" type="text" [(ngModel)]="appservice.Cheque_Date"
                                    name="Cheque_Date" #Cheque_Date="ngModel"></td>
                            </tr>
            
                            <tr [className]="appservice.Pay_Mode==appservice.Card_ID ? '' : 'hidden'">
                                <td>Card Charge</td>
                                <td><input class="form-control" type="text" [(ngModel)]="appservice.Card_Charge"
                                    name="Card_Charge" #Card_Charge="ngModel"></td>
                            </tr>
            
                            <tr>
                                <td>Remarks</td>
                                <td> <input class="form-control" type="text" [(ngModel)]="appservice.Remarks" name="Remarks"
                                    #Remarks="ngModel"></td>
                            </tr>
                        </table>
                    </td>
                    </tr>
      
  
                    </tbody>
  
                  </table>
  
                  <div class="invoice-btn-box text-right " style="padding-bottom:20px">
              
                    <button class="btn btn-info rounded-pil" [disabled]="btndisable" type="submit"> 
                      
                      
                      <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  
  
                      <i  *ngIf="btndisable==false" class='bx bx-paper-plane'></i>
                      
                      
                     Save </button> &nbsp;&nbsp;
                 <button class="btn btn-secondary rounded-pil " type="button" (click)="Back()">Cancel</button>  &nbsp;&nbsp;
                </div>
  
                </div>
              </div>
  
              <div class="col-md-6">
                <br>
                  <p-table class="table table-striped custom-table" [value]="Rows" [columns]="cols" [paginator]="true" [rows]="10"

                  
                    [resizableColumns]="true" sortMode="multiple" selectionMode="single">
                    <ng-template pTemplate="header">
                      <tr>
                        <th *ngFor="let col of cols" [pSortableColumn]="col.field" width="{{col.width}}">
                          {{col.header}}
                          <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
                          </p-sortIcon>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-car>
                      <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of cols"  style.text-align="{{col.align=='right' ? 'right' : 'left'}}">
                          {{car[col.field]}}
                        </td>
                      </tr>
                    </ng-template>
            
                    <ng-template pTemplate="footer">
                      <tr>
                          <td colspan="2">Total</td>
                          
                          <td style="text-align: right;color:midnightblue;font-size: 14px;font-weight: 900">{{cr|currency:' ':true}}</td>
                          <td style="text-align: right;color:midnightblue;font-size: 14px;font-weight: 900">{{db|currency:' ':true}}</td>
                         
                      </tr>
                      <tr>
                              <td colspan="2">Balance</td>
                              
                             
                              
                              <td colspan="2" style="text-align: right;color:darkred;font-size:
                               14px;font-weight: 900">{{Total|currency:' ':true}}</td>
                             
                          </tr>
                  </ng-template>
            
                  </p-table>
                </div>
  
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  
  
  