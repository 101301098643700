<div class="row">
  <div class="col-md-12 nopad">

    
      <div class="col-md-12 nopad">


        <div class="w_79" style="position: fixed;z-index: 99;  font-size: 13px; margin-top: -1px; font-weight: 600; color: darkslategray; padding-right: 5px;padding-left: 5px; z-index: 990; background-color: white; ">

          <table style="width: 100%;  "><tr>
              <td style="color: darkslategray;">
                <span>{{appservice.Selected_Customer.Short_Name}} </span> 
                 <span *ngIf="appservice.Selected_Customer.Short_Name==''">{{appservice.Selected_Customer.Ledger_Name}} </span> 
                  <br>
                 {{appservice.Selected_Customer.Area}} <span *ngIf="(appservice.Selected_Customer.GSTIN!='0' && appservice.Selected_Customer.GSTIN!='')" style="color:crimson;">GST</span>

              </td>
              <td style="text-align: right;  font-size: 12px;    color:#333333;"> 
              
             {{appservice.Order_No}}<br>
            {{appservice.Today_Date}}
              </td>
          </tr>
          <tr>
            <td     >
            <select placeholder="Select Category..."    [disabled]= "isError()"      style="border-radius: 15px!important;" class="select2-option form-control form-control-sm" aria-hidden="true"
            [(ngModel)]="appservice.item_Category_" placeholder=" " name="Category" (change)="get_filter_data()"
            #Category="ngModel" required >
            <option value="All">
              All
          </option>
            
        <option *ngFor="let row of appservice.Stock_Category_Row" value={{row.value}}>
            {{row.value}}
        </option>
      
    </select>

  </td>
    <td ><input type="text" placeholder="Search item.."  [readonly]= "isError()"  
      [(ngModel)]="Search_text_" name="Search_text"
            #Search_text="ngModel" 
        (keyup)="Search_Item_byName(Search_text_)" style="border-radius: 15px!important;"
         class="form-control form-control-sm"></td>  
          </tr>
        
        </table>



          <div>


          </div>

        </div>
      </div>
    

  
      <div class="col-md-12 nopad">


       

      

        </div>
        
        <div class="m_2 res_table" style="margin-top: 75px;">

         
          <div *ngIf="(((appservice.Stcok_Row | json) == '[]') ||  ((appservice.Stcok_Row | json) == null) )" >
            <div class="d-table" style="width:100%;">
                <div class="d-table-cell">
                    <div class="maintenance-content" style="box-shadow:none ; background: none; ">

                      
                      <div class="spinner-border text-primary"  *ngIf="appservice.loading" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>


                       <div>
                         <br>

                        <a style="height: 100px; width: 100px;" class="logo">
                            <img src="assets/img/cart-256.png" alt="image">
                        </a>
                       
                        <p>Item not availabel please select other category</p>
                       </div>
                       
                    </div>
                </div>
            </div>
        </div>


          <div class="col-md-12 " style="background-color: #dbe4ec4d;"> 

           

            <div class="row">
              <div class="col-sm-4 col-md-3 " style="padding: 1px;" *ngFor="let data of appservice.Stcok_Row">
                  <div class="Cart-Card">
                    


                      
                    <table>
                      <tr>
                        <td style="text-align: left;" colspan=2>
                          <div class="prod-Name" style="    color: #084a79;
                          font-weight: 700;">{{data.Item_Name}}  <span  *ngIf="data.MRP>0" style="font-size: 13px;"> {{(((((data.MRP)*(data.Box_Qty))/(data.Rate))*100)-100).toFixed(2)}}%</span></div>
                      
                        </td>
                       
                      </tr>
                      <tr>
                        <td style="text-align: left;     color: #036d1a;
                        font-weight: 600;">  Stock : <span *ngIf="data.Stock>0">{{data.Stock}}</span> <span *ngIf="!(data.Stock>0)" style="color: #fa1f1f;" >-</span>  

<span *ngIf="((data.Landing_Cost)>(data.LP_Rate) &&   (data.LP_Rate)!=0 ) "  style="color: #fb4747;"  > (+)</span> 
<li *ngIf="((data.Landing_Cost)>(data.LP_Rate) &&   (data.LP_Rate)!=0 )" style="color: #fb4747;
font-weight: 700;
font-size: 14px; animation: ring 4s .7s ease-in-out infinite;" class="bx bx-bell"></li>

<span *ngIf="((data.Landing_Cost)<(data.LP_Rate) &&   (data.LP_Rate)!=0  ) "  style="color:  #044412;"  > (-)</span> 

<li *ngIf="((data.Landing_Cost)<(data.LP_Rate) &&   (data.LP_Rate)!=0  ) "  style="color: #044412;
  font-weight: 700;
  font-size: 14px; animation: ring 4s .7s ease-in-out infinite;" class="bx bx-bell"></li>






                        </td>
                        <td style="text-align: right;" ><span style="font-size: 12px; color: #084a79;"> MRP :  {{data.MRP}} </span></td>
                      </tr>
                      <tr>
                        <Td style="text-align: left; font-size: 12px;"> L Rate : {{data.L_Rate}} <span  style="color: darkgray;" *ngIf="data.L_Rate>0"> / {{(((((data.MRP)*(data.Box_Qty))/(data.L_Rate))*100)-100).toFixed(2)}}%</span>
                        
                        

                        </Td>
                        <Td *ngIf="rate_disp==true" style="text-align: right;font-size: 12px;">P Rate : {{data.Landing_Cost}} <span *ngIf="data.Disc_per>0">/{{data.Disc_per}}%</span></Td>
                      </tr>
                      <tr>
                        <Td style="text-align: left;font-size: 11px; color:  rgba(0, 0, 0, 0.548);">{{data.L_Date}} <span *ngIf="(data.LP_Rate!=0  && rate_disp==true)" >/ {{data.LP_Rate}}  </span>  
                        
                        

                        </Td>
                        <Td *ngIf="rate_disp==true" style="text-align: right; font-size: 11px; color: rgba(0, 0, 0, 0.548)">{{data.P_Date}}
                        
                        </Td>
                      </tr>
                      <tr>
                        <Td colspan="2">
                        
                          <table style="width: 100%;">

                            <tr>
                              <td>
                                <li class="bx bx-rupee" style="    position: absolute;
                          
                                COLOR: BLACK;
                                bottom: 20px;"></li>

                                <input type="number" style="width: 100%; padding-left: 15px;border-color: gainsboro; background: #f4fff51a; border-style: unset; 
                                border-bottom-style:solid;"  
                                 [(ngModel)]="data.Order_Rate"
                                 name="Order_Rate" #Order_Rate="ngModel"
                                (keyup)="add_row(data)" placeholder="Rate ">
                              </td>
                              <td style="padding-left: 10px; padding-right: 10px;">
                            
                                <input type="number"  (keyup)="add_row(data)" 
                      
                            [(ngModel)]="data.Order_Qty"
                            name="Order_Qty" #Order_Qty="ngModel"  
                             style="text-align: right; width: 100%;border-color: gainsboro; background: #f4fff51a;border-style: unset;border-bottom-style: solid; "
                             [style.color]="data.Order_Qty>0 ? 'Blue' : 'black' "
  
                             placeholder="Qty">
                              </td>
                              <td>

                                
                             <input type="number"  (keyup)="add_row(data)" 
                      
                             [(ngModel)]="data.Free"
                             name="Free" #Free="ngModel"  
                              style="text-align: right; width: 100%;border-color: gainsboro; background: #f4fff51a;border-style: unset;border-bottom-style: solid; "
                              [style.color]="data.Free>0 ? 'Blue' : 'black' "
   
                              placeholder="Free">
   
                                
                              </td>
                            </tr>
                          </table>
                          
                         

                         

                            
                             

                          
                          
                        </Td>
                        
                      </tr>
                    </table>

                    

                    
                 

                  
      

                 

                  <div class="mrp"  style="position: absolute; bottom: -6px; text-align: center;">
                    <span style="font-size: 10px; color:red;"> {{data.error}}</span>
                    </div>
      
                    
                      
      
                  </div>
              </div>
          </div>

          

        </div>

       
      </div>
    

      
      <div class="col-md-12 nopad">

          <div class="w_79" style="  bottom: 0;
              font-weight: 700;
              position: fixed;">
              
              
              <table style="width: 100%;"><tr>
               
                <td style="text-align: right;     color: rgb(46, 45, 128);"> 
                 
                  <a  [class.disabled]="isload" style="border-radius: 0px;color: white; background-color: rgb(8, 83, 145);  border-color: rgb(8, 83, 145);"    class="btn btn-danger btn-block">
                                          
                      <span class="bx bx-basket" role="status" aria-hidden="true"></span>

                       {{Total}} </a>
              </td>

              <td style="color: white;">
                <a style="border-radius: 0px;"  [class.disabled]="iserror" (click)="appservice.load_page1('/view-order-details')" class="btn btn-secondary btn-block">Next</a>

            </td>
            </tr>
          </table>


  
              </div>
        </div>
     

   
     
  </div>
</div>








