<div class="row">
    <div class="col-md-12 nopad">
  
      <div class="col-md-12 nopad mobile_hide">
       
        <div class="w_79" style="position: fixed;z-index: 99; ">
        
          <div class="tabs-header border-bottom">
            <ul>
              <li  *ngFor="let data of appservice.Profit_Report" 
                [className]="appservice.URL_Location==data.Link ? 'active' : ''"  class="" > 
                <a (click)="appservice.load_page(data.Link)">{{data.Menu}}</a></li>
                    
            </ul>
          </div>
  
          <div class="right-corner">
            <div class="dropdown"style="display: inline-flex;">
              
              <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" data-placement="bottom"
              title="search" >
             
                <i style="    font-size: 20px;" class='bx bx-search'></i> 
              </a>
  
  
              <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                aria-expanded="false">
                
                <i class='bx bx-filter'></i>
              </button>
              <div class="dropdown-menu" style="width: 325px;">
                <div style="background-color: teal; color: white; width: 100%; ">
                  &nbsp;&nbsp;Search</div>
                <table>
                 
               
                  <tr>
                    <td style="width: 80px;">From </td>
                    <td>
                      <input type="date" class="area form-control form-control-sm" [(ngModel)]="appservice.S_From"
                        name="From_" #From_="ngModel">
                    </td>
                  </tr>
  
                  <tr>
                    <td style="width: 80px;">To </td>
                    <td>
                      <input type="date" class="area form-control form-control-sm" [(ngModel)]="appservice.S_To"
                        name="To_" #To_="ngModel">
                    </td>
                  </tr>
  
                 
                 
  
                  <tr>
                    <td> </td>
                    <td><button type="button" (click)="appservice.get_Overall_Profit_Details()"
                        class="btn btn-info btn-sm">Search</button></td>
                  </tr>
                </table>
                <div  class="search_Footer border-top" style="color: green;"> 
                  <div (click)="export_excel()"  class="buttons">
                    <span><li class="bx bx-download"></li></span>
                   <span> Export Excel</span>
                   
                  </div>
      
                 
                  <div (click)="export_pdf(dt)"  class="buttons " style="color: red;">
                    <span><li class="bx bx-download"></li></span>
                   <span> Export PDF</span>
                   
                  </div>
      
      
              </div>
             
              </div>
            </div>
          </div>
  
        </div>
      </div>
  
      <div class="col-md-12 nopad">
        <div class="spin_block" *ngIf="appservice.isload">
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="m_2 res_table">
          <div class="search-bar_Ext mhide  show_bar " style="right: 80px;">
          
            <li  class="bx bx-left-arrow-alt mshow  search_back"></li>
  
                <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                class="search-form-control text"
                placeholder="Search...">
    
          </div>
  
          <table>
  
            <tr>
            
              <td class="mhide">
               <span style="font-size: 11px;">From</span>
                <input type="date" style="max-width:160px;" class="form-control form-control-sm" [(ngModel)]="appservice.S_From" name="From_"
                  #From_="ngModel">
              </td>
          
  
  
            
            
              <td class="mhide">
  
                <span style="font-size: 11px;">To</span>
                <input type="date" style="max-width:160px;" class="area form-control form-control-sm" [(ngModel)]="appservice.S_To"
                  name="To_" #To_="ngModel">
              </td>
           
  
             
  
  
              
          
              
              <td class="mhide">
                <span style="font-size: 11px;">&nbsp;</span>
                <button type="button" (click)="appservice.get_Sales_Profit_Details()"
                  class="btn btn-info btn-sm">Search</button></td>
            </tr>
          </table>
          <p-table #dt class="table table-striped custom-table" [value]="appservice.Sales_Profit_Row"
            selectionMode="single"[globalFilterFields]="appservice.Saleswise_Profit_GF">
            <ng-template pTemplate="header">
              <tr>
                <th >#</th>
                
                <th>Narration</th>
               
                <th style="text-align: right;">Credit</th>
                <th style="text-align: right;">Debit</th>
                
              </tr>
            </ng-template>
  
           
             
            
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
  
            
  
              <tr>
                <td >{{rowIndex+1}}</td>
               
                <td>{{rowData.Naration}}
                 
                </td>
                
  
               
                <td style="text-align: right;">{{rowData.CR_AMT}}</td>
                <td style="text-align: right;">{{rowData.DB_Amt}}</td>
               
              </tr>
            </ng-template>
           
          </p-table>
        </div>
      </div>
      <div class="col-md-12 nopad">
        <div class="w_79" style="  bottom: 0;
                  font-weight: 700;
                  color: #333333;
                  background-color: #f4f4f4;
                  padding: 0.571em 0.857em;
                  position: fixed;
                  bottom: 0;
              
                  
                  border: 1px solid #c8c8c8;">
          <table style="width: 100%;">
            <tr>
              <td style="color: darkslategray;">Total Items : {{appservice.length_of(dt)}} </td>
              <td style="text-align: right;     color: crimson;"> Credit :
                {{appservice.sum_of(dt,'CR_AMT')| number:'1.0-0'}}</td>
                <td style="text-align: right;     color: crimson;"> Debit :
                  {{appservice.sum_of(dt,'DB_Amt')| number:'1.0-0'}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  