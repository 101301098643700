
<div class="row">
    <div class="col-md-12 nopad">
       <div class="col-md-12 nopad mobile_hide">
  
  
          <div class="w_79" style="position: fixed;z-index: 99; ">
            <div class="tabs-header border-bottom">
              <ul>
  
                <li  > <a  style="display: inline-flex;"> <span style="   
                 font-size: 23px; color:#4788ff" class="bx bx-group"></span>Reference Group</a></li>
             
  
              </ul>
            </div>
            <div class="right-corner">
  
              <div class="dropdown" style="display: inline-flex;">
  
                <a class="dropdown-toggle   addbtn1"(click)="addReset()"  data-toggle="tooltip" data-placement="bottom"
                title="Add New">
  
                <i class='bx bx-list-plus'></i>
              </a>
              <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" data-placement="bottom"
              title="search" >
             
                <i style="    font-size: 20px;" class='bx bx-search'></i> 
              </a>


              <a  style="padding-left: 10px;padding-right: 10px;" class="dropdown-toggle " 
              (click)="appservice.get_Reference_Group()" data-toggle="tooltip" data-placement="bottom"
              title="Refresh">

              <i class='bx bx-rotate-left'></i>
            </a>
           
           

          <div class="dropdown-menu" style="width: 325px;">
            <div style="background-color: teal; color: white; width: 100%; ">
              &nbsp;&nbsp;Search</div>
            <table>


             
              



             

              <tr>
                <td> </td>
                <td><button type="button" (click)="appservice.get_Reference_Group()"
                    class="btn btn-info btn-sm">Search</button></td>
              </tr>

            </table>


            
          </div>
         

            </div>
            </div>
  
  
  
          
  
          </div>
        </div>
      
  
    
        <div class="col-md-12 nopad">
  
  
          <div class="spin_block" *ngIf="appservice.isload">
  
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
  
          </div>
          <div class="m_2 res_table">

          <div class="search-bar_Ext mhide  show_bar " style="right: 110px;">
        
            <li  class="bx bx-left-arrow-alt mshow  search_back"></li>

                <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                class="search-form-control text"
                placeholder="Search...">
    
          </div>
  
            
  
  
            <p-table #dt class="table table-striped custom-table" [value]="appservice.Reference_Group_Rows"
            selectionMode="single"[globalFilterFields]="appservice.Reference_Group_GF">
            <ng-template pTemplate="header">
              <tr>
                <th>#</th>
                <th [pSortableColumn]="'RG_iID'">ID</th>
                <th [pSortableColumn]="'RG_vCode'">Code</th>
                <th [pSortableColumn]="'RG_vDescription'">Description </th>
                <th ></th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr>
                <td>{{rowIndex+1}}</td>
                <td >{{rowData.RG_iID}}</td>
                <td >{{rowData.RG_vCode}}</td>
                <td>{{rowData.RG_vDescription}}</td>
                <td style="text-align: center;">
  
                  <div class="dropdown">
                    <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more"
                      aria-haspopup="true" aria-expanded="false">
                      <i class='bx bxs-badge-check' style="color: #4788ff;"></i>
                    </button>
                    <div class="dropdown-menu">
                    
                     
                      <a class="dropdown-item d-flex align-items-center" (click)="onDelete(rowData.RG_iID)">
                        <i class='bx bx-trash'></i> Delete
                      </a>

                    </div>
                  </div>

                </td>

              </tr>
            </ng-template>
          </p-table>

          </div>
        </div>
        <div class="col-md-12 nopad">
          <div class="w_79" style="  bottom: 0;
                  font-weight: 700;
                  color: #333333;
                  background-color: #f4f4f4;
                  padding: 0.571em 0.857em;
                  position: fixed;
                  bottom: 0;
              
                  
                  border: 1px solid #c8c8c8;">
            <table style="width: 100%;">
             
            </table>
          </div>
        </div>
  
    
     
    </div>
  </div>



